import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { loggingService } from './ServiceFactory';

interface RequestConfigWithCustomData extends AxiosRequestConfig {
    // only option removed again
    // but this way data to the interceptor could be passed
    // hence keeping this class here
}

const authInterceptor = config => {
    let keyToUse = "iam.access-token";
    if (config.url === "user/login") {
        loggingService.debug("Injectng no token");
        keyToUse = null;
    }
    else if (config.url === "user/login/certificate") {
        loggingService.debug("Injectng no token");
        keyToUse = null; // no token needed
    }
    else if (config.url === "user/login/clonetoken") {
        loggingService.debug("Injectng no token");
        keyToUse = null; // Clonetoken must be added by invoking party
    }
    else if (config.url === "user/token/refresh") {
        loggingService.debug("Injectng refresh token");
        keyToUse = "iam.refresh-token";
    }

    if (!keyToUse) {
        return config;
    }

    const token = localStorage.getItem(keyToUse);
    if (token) {
        loggingService.debug("Injecting token");
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
};

const responseSuccessInterceptor = response => {
    /*
    if (response.config.ReturnData) {
        return response.data;
    }
    */
    return response;
};

const responseFailInterceptor = response => {
    return Promise.reject(response);
};

export default abstract class BaseApiService {
    private baseUrl: string;
    private httpClient: AxiosInstance;
    private sectionIdentifier: string;

    constructor(sectionIdentifier: string) {
        this.baseUrl = "/api/v1/";
        this.sectionIdentifier = sectionIdentifier;


        const config = {
            baseURL: this.baseUrl,
            timeout: 5000,
            headers: { 'Content-Type': 'application/json' }
        };

        this.httpClient = axios.create(config);
        this.httpClient.interceptors.request.use(authInterceptor);
        this.httpClient.interceptors.response.use(responseSuccessInterceptor, responseFailInterceptor);
    }

    protected makeGet(url: string, retries: number = 2): Promise<AxiosResponse<any, any>> {
        return this.httpClient.get(this.sectionIdentifier + url)
            .then((response) => {
                if ([200, 201, 204].includes(response.status) || response.status == 404 || retries === 0) {
                    return response;
                }
                loggingService.warn("have to retry this request");
                return this.makeGet(url, retries - 1);
            });
    }

    protected makePost(url: string, data: any, headers: any = {}, timeout?: number): Promise<AxiosResponse<any, any>> {
        const config: RequestConfigWithCustomData = {
            headers: headers
        };
        if (timeout !== undefined) {
            config.timeout = timeout;
        }
        return this.httpClient.post(this.sectionIdentifier + url, data, config);
    }

    protected makePostSuccessResponse(url: string, data: any, headers: any = {}, timeout?: number): Promise<void> {
        return this.makePost(url, data, headers, timeout)
            .then(response => {
                if (response.data.success === true) {
                    return Promise.resolve();
                }
                else {
                    return Promise.reject("API returned non-success.")
                }
            })
            .catch(reason => Promise.reject(reason));
    }
}
