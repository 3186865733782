<template>
  <router-view />
</template>

<script>
  import * as faceapi from '@vladmandic/face-api'
  import initImageSrc from "@/assets/init.jpg"
  import Settings from "@/js/settings.js"
  import { getCookieValue } from '@/js/auth'
  import { loggingService, maintenanceApiService } from "@/services/ServiceFactory"

  export default {
    mounted() {
      // check if access token is still valid
      this.$store.dispatch("initTokensAndRefresher")

      if(localStorage.getItem(Settings.INIT_FACE_API.key) == "true"){
        this.initFaceApi()
      }

      // fullscreen change
      document.addEventListener("fullscreenchange", () => {
          this.$store.dispatch("fullscreenChange")
      });

      // highlight dev environment
      this.highlightHeaderByEnvirement();

      // check regularly for updates and refresh browser if so
      maintenanceApiService.setupIntervalUpdateChecker();
    },
    methods: {
      async initFaceApi() {
          let modelPath = "model"
          await faceapi.nets.tinyFaceDetector.load(modelPath);
          await faceapi.nets.faceLandmark68TinyNet.loadFromUri(modelPath)
          await faceapi.nets.faceRecognitionNet.loadFromUri(modelPath)
          const initImage = await faceapi.fetchImage(initImageSrc)
          await faceapi.detectAllFaces(initImage, new faceapi.TinyFaceDetectorOptions())
              .withFaceLandmarks(true)
              .withFaceDescriptors();
          loggingService.info("Face API is ready");
          this.$store.commit("faceApiIsReady")
      },
      highlightHeaderByEnvirement() {
        const cookieval = getCookieValue("highlight_environment_identifier")
        let color = undefined
        if (cookieval == "LOCAL") {
          color = "lightgreen";
        } else if (cookieval == "STAGING") {
          color = "orange"
        }
        if (color !== undefined) {
          const css = ".highlight-environment { background-color: " + color + " !important; }";
          var styleSheet = document.createElement("style")
          styleSheet.textContent = css
          document.head.appendChild(styleSheet)
        }
      }
    }
  }
</script>

<style lang="scss">

  @import '@/assets/global.scss';
  
</style>
