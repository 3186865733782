import BaseApiService from "./BaseApiService"
import store from "@/store"

export default class PlayerApiService extends BaseApiService {
    constructor() {
        super('player');
    }

    loadPlayers() {
        if (!store.state.scopes.includes("api.player.br")) {
            return Promise.resolve([])
        }

        return this.makeGet('/load_all')
            .then(response => response.data as string[]);
    }

    loadPlayer() {
        return this.makeGet('')
            .then(response => response.data);
    }
}
