import BaseApiService from "./BaseApiService"
import store from "@/store"
import { canEditId } from "@/shared"
import { loggingService } from "./ServiceFactory";
import { IDartsGame } from "@/models/Game"

export default class GameApiService extends BaseApiService {
    constructor() {
        super('game');
    }

    saveGame(gameid, update) {
        if (!canEditId(gameid))
            return new Promise(() => false);

        let synched = true;
        if ('synched' in update) {
            synched = update['synched']
            //do not send to server
            delete update['synched']
        }
        return this.makePost("", { id: gameid, content: update })
            .then(response => {
                if ([200, 201].includes(response.status)) {
                    if (synched === false) {
                        loggingService.info('Synched game');
                    }
                    return true
                }
                else {
                    throw new Error(`HTTP error: ${response.status}`);
                }
            })
            .catch(error => {
                //Otherwise many logs
                if (synched === true) {
                    loggingService.error(`Error: ${error}`);
                }
                return false
            });
    }

    loadGame(gameid) {
        if (!store.state.scopes.includes("api.game.r")) {
            return Promise.resolve(undefined)
        }
        return this.makeGet(`/${gameid}`)
            .then(response => response.data);
    }
    loadGamesLastTwoWeeks() {
        if (!store.state.scopes.includes("api.game.br:TIME")) {
            return Promise.resolve(new Map<string, IDartsGame>());
        }
        return this.makeGet(`/last_two_weeks`)
            .then(response => response.data)
            .then(transformGamesToMap);
    }
    loadGamesOngoing() {
        if (!store.state.scopes.includes("api.game.br:ONGOING")) {
            return Promise.resolve(new Map<string, IDartsGame>());
        } // FIXME remove this if statement, make the check before calling this function // FIXME remove this if statement, make the check before calling this function
        return this.makeGet("/ongoing")
            .then(response => response.data)
            .then(transformGamesToMap);
    }
    loadGamesByTid(tid) {
        if (store.state.scopes.includes("api.game.br:TID") || (tid && store.state.editHash && tid.startsWith((store.state.editHash as any).id))) {
            return this.makeGet(`/load_by_tid/${tid}`)
                .then(response => response.data)
                .then(transformGamesToMap);
        }
        else {
            return Promise.resolve(new Map<string, IDartsGame>());
        }
    }
    loadGamesByPlayer() {
        if (store.state.scopes.includes("api.game.br:OWN")) {
            return this.makeGet(`/load_by_player`)
                .then(response => response.data)
                .then(transformGamesToMap);
        } else {
            return Promise.resolve(new Map<string, IDartsGame>());
        }
    }

    loadGamesByPlayersLastN(playernames) {
        if (store.state.scopes.includes("api.game.br:PLN")) {
            return this.makeGet(`/load_by_players_lastn?` + playernames.map(p => `player=${p}`).join('&'))
                .then(response => response.data)
                .then(transformGamesToMap);
        }
        else {
            return Promise.resolve(new Map<string, IDartsGame>())
        }
    }
    loadGamesBetweenPlayersLastN(playernames) {
        if (store.state.scopes.includes("api.game.br:P2P")) {
            return this.makeGet(`/load_between_players_lastn?` + playernames.map(p => `player=${p}`).join('&'))
                .then(response => response.data)
                .then(transformGamesToMap);
        }
        else {
            return Promise.resolve(new Map<string, IDartsGame>())
        }
    }
}

function transformGamesToMap(data: any) {
    return new Map<string, IDartsGame>(data.map(game => [game.id, { ...JSON.parse(game.content), last_changed: game.last_changed }]))
}
