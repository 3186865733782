
import GameApiService from "./GameApiService";
import UserApiService from "./UserApiService";
import TournamentApiService from "./TournamentApiService";
import PlayerApiService from "./PlayerApiService";
import LoggingService from "./LoggingService";
import MaintenanceApiService from "./MaintenanceApiService";

// creating the services is simple so the factory is not really needed
// but in the beginning I thought we would have more complex logic here
export class ServiceFactory {
    private loggingService: LoggingService;
    private userApiService: UserApiService;
    private gameApiService: GameApiService;
    private tournamentApiService: TournamentApiService;
    private playerApiService: PlayerApiService;
    private maintenanceApiService: MaintenanceApiService;

    constructor() {
        this.loggingService = new LoggingService();
        this.userApiService = new UserApiService();
        this.gameApiService = new GameApiService();
        this.tournamentApiService = new TournamentApiService();
        this.playerApiService = new PlayerApiService();
        this.maintenanceApiService = new MaintenanceApiService();
    }

    getUserApiService() {
        return this.userApiService;
    }
    getGameApiService() {
        return this.gameApiService;
    }
    getTournamentApiService() {
        return this.tournamentApiService;
    }
    getPlayerApiService() {
        return this.playerApiService;
    }
    getLoggingService() {
        return this.loggingService;
    }
    getMaintenanceApiService() {
        return this.maintenanceApiService;
    }
}

const serviceFactory = new ServiceFactory();

const loggingService = serviceFactory.getLoggingService();
const userApiService = serviceFactory.getUserApiService();
const gameApiService = serviceFactory.getGameApiService();
const tournamentApiService = serviceFactory.getTournamentApiService();
const playerApiService = serviceFactory.getPlayerApiService();
const maintenanceApiService = serviceFactory.getMaintenanceApiService();

export { loggingService, userApiService, gameApiService, tournamentApiService, playerApiService, maintenanceApiService };
