import { LogEntry, LogLevel } from "@/models/Logging";
import { maintenanceApiService } from "./ServiceFactory";

const LOGREF_DISCARD_TIMEOUT_MINUTES = 1 * 60 * 1000;
const POSTBACK_ERROR_DEACTIVATION_MINUTES = 15;

export default class LoggingService {
    private history: LogEntry[] = [];
    private logLevels: Set<LogLevel>;
    private logRef: string = null;
    private lastErrorLogged: Date = null;

    // eslint-disable-next-line
    constructor(private maxHistory: number = 50, logLevels: LogLevel[] = ['info', 'warn', 'error', 'debug'], private consolePrintingEnabled: boolean = true, private postbackEnabled: boolean = true) {
        this.logLevels = new Set(logLevels);
    }

    private addToHistory(level: LogLevel, message: any, args: any[]) {
        if (this.history.length >= this.maxHistory) {
            this.history.shift();
        }
        this.history.push({ level, message, args, timestamp: new Date() });
    }

    public info(message: any, ...args: any[]) {
        this.addToHistory('info', message, args);
        if (this.logLevels.has('info') && this.consolePrintingEnabled) {
            console.info(message, ...args);
        }
    }

    public warn(message: any, ...args: any[]) {
        this.addToHistory('warn', message, args);
        if (this.logLevels.has('warn') && this.consolePrintingEnabled) {
            console.warn(message, ...args);
        }
    }

    public error(message: any, ...args: any[]) {
        this.addToHistory('error', message, args);
        if (this.logLevels.has('error') && this.consolePrintingEnabled) {
            console.error(message, ...args);
        }
        if (this.lastErrorLogged && (new Date().getTime() - this.lastErrorLogged.getTime()) > LOGREF_DISCARD_TIMEOUT_MINUTES * 60000) {
            this.logRef = null;
        }
        this.lastErrorLogged = new Date();
        this.postBackLogs();
    }

    public debug(message: any, ...args: any[]) {
        this.addToHistory('debug', message, args);
        if (this.logLevels.has('debug') && this.consolePrintingEnabled) {
            console.debug(message, ...args);
        }
    }

    public getHistory() {
        return this.history;
    }

    private postBackLogs() {
        if (this.postbackEnabled) {
            maintenanceApiService.postBackClientLogs(JSON.stringify(this.getHistory()), this.logRef)
                .then(r => this.logRef = r.logref)
                .catch(() => {
                    this.warn("Error during postback - deactivating for " + POSTBACK_ERROR_DEACTIVATION_MINUTES + " minutes");
                    //this.postbackEnabled = false;
                    setTimeout(() => {
                        this.info("re-acticating postback after " + POSTBACK_ERROR_DEACTIVATION_MINUTES + " minutes");
                        this.postbackEnabled = true;

                    }, POSTBACK_ERROR_DEACTIVATION_MINUTES * 60000);
                    return Promise.resolve();
                });
        }
    }
}
