import { IAppInformation, ILogPostBackResult } from "@/models/Maintenance";
import BaseApiService from "./BaseApiService"
import { loggingService } from "./ServiceFactory";



export default class MaintenanceApiService extends BaseApiService {
    private previousBuildIdentifier: string | null = null;

    constructor() {
        super('administrative');
    }

    public setupIntervalUpdateChecker() {
        this.checkForBuildIdentifierChange();
        setInterval(() => this.checkForBuildIdentifierChange(), 1 * 3600000); // hours in milliseconds
    }

    private fireActionNoResponse(url: string, data: any, errormessage: string): Promise<void> {
        return this.makePost(url, data)
            .then(response => {
                if (response.status === 204) {
                    return Promise.resolve();
                }
                return Promise.reject();
            })
            .catch(error => {
                loggingService.error(errormessage, error);
                return Promise.reject();
            });
    }

    getAppInformation(): Promise<IAppInformation> {
        return this.makeGet('/appinfo')
            .then(response => response.data as IAppInformation);
    }

    private checkForBuildIdentifierChange(): void {
        this.getAppInformation()
            .then(info => {
                if (this.previousBuildIdentifier && this.previousBuildIdentifier !== info.build_identifier) {
                    loggingService.info('Build identifier changed, reloading page');
                    window.location.reload();
                }
                this.previousBuildIdentifier = info.build_identifier;
            })
            .catch(error => {
                loggingService.error('Failed to get app information', error);
            });
    }

    recalculateELO() {
        return this.fireActionNoResponse('/elo/calculation', {}, "Error triggering ELO recalculation");
    }
    redeployScopes() {
        return this.fireActionNoResponse('/scopes/redeploy', {}, "Error triggering scope redeployment");
    }
    cleanupExpiredTokens() {
        return this.fireActionNoResponse('/tokens/cleanup', {}, "Error triggering token cleanup");
    }
    postBackClientLogs(logs: string, logref:string = undefined) {
        const data = {
            "logs": logs,
            "logref": logref
        }
        return this.makePost("/clientlogs", data).then(r => r.data as ILogPostBackResult);
    }
}
