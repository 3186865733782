import { createStore } from 'vuex'
import { getCookieValue, decodeJWT } from '@/js/auth'
import { loggingService, userApiService } from '../services/ServiceFactory'
import * as faceapi from '@vladmandic/face-api'

/*
I still do not like the bootstrapping of the store w.r.t. the tokens and the local storage.
It is quite complex and I am not sure if it is the best way to do it.
Maybe just start with a Guest State and then the init method will overwrite it with the correct state?
But now everything is in one file at least. Further refactoring is welcome.
*/

let token = localStorage.getItem("iam.access-token")
let token_decoded = decodeJWT(token)

if (token && !token_decoded) {
  console.log("clear access token")
  localStorage.removeItem("iam.access-token")
  token = undefined
  token_decoded = undefined
}

let identity = undefined
if (token_decoded) {
  identity = JSON.parse(localStorage.getItem("iam.identity"))
}

let userscopes_cookie = getCookieValue('userscopes')
if (userscopes_cookie)
  userscopes_cookie = atob(userscopes_cookie).split(',')

let editHash_cookie = getCookieValue('edit_uuid')
if (editHash_cookie) {
  editHash_cookie = JSON.parse(atob(editHash_cookie))
  let now_seconds = Date.now() / 1000
  if (editHash_cookie['created'] < (now_seconds - 60 * 60 * 24)) {
    editHash_cookie = undefined
  }
}

let username_cookie = getCookieValue('username')
if (username_cookie)
  username_cookie = atob(username_cookie)

function handleTokenResponse(data) {
  loggingService.info("handleTokenResponse")
  const _atoken = data["token"]
  const _rtoken = data["refresh_token"]
  let _decoded_atoken = decodeJWT(_atoken)
  if (_decoded_atoken) {
    localStorage.setItem("iam.access-token", _atoken)
    if (_rtoken)
      localStorage.setItem("iam.refresh-token", _rtoken)

    let identity = null
    if ("identity" in data) {
      identity = data["identity"]
      localStorage.setItem("iam.identity", JSON.stringify(identity))
    }
    return {
      "decoded_access_token": _decoded_atoken,
      "identity_information": identity
    }
  }
  return undefined
}

function _common_login({ commit }, loginRequest) {
  return loginRequest.then(
    _tokens => {
      let _decoded = handleTokenResponse(_tokens)
      if (_decoded) {
        commit('loginSuccess', _decoded);
        return Promise.resolve();
      }
      else {
        commit('loginFailure');
        return Promise.reject();
      }
    }
  )
  .catch(error => {
    commit('loginFailure');
    return Promise.reject(error);
  });
}

export default createStore({
  state: {
    scopes: token_decoded ? token_decoded["scopes"] : userscopes_cookie || [],
    username: token_decoded ? token_decoded["sub"] : username_cookie,
    displayname: identity ? identity["displayname"] : token_decoded ? token_decoded["sub"] : username_cookie,
    isLoggedIn: token !== undefined && token !== null,
    isMobile: window.innerWidth <= window.innerHeight,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    isFullscreen: document.fullscreenElement != null,
    editHash: editHash_cookie,
    faceapi: faceapi,
    faceapi_isReady: false,
  },
  getters: {
  },
  mutations: {
    loginSuccess(state, data) {
      const _decoded = data["decoded_access_token"];
      const identity = data["identity_information"];

      state.scopes = _decoded["scopes"];
      state.username = _decoded["sub"];
      state.displayname = (identity != null) ? identity["displayname"] : _decoded["sub"];
      state.isLoggedIn = true;
    },
    loginFailure(state) {
      state.scopes = userscopes_cookie || []
      state.username = username_cookie;
      state.displayname = username_cookie;
      state.isLoggedIn = false;
    },
    logout(state) {
      state.scopes = userscopes_cookie || []
      state.username = username_cookie;
      state.displayname = username_cookie;
      state.isLoggedIn = false;
    },
    switchIsMobile(state) {
      state.isMobile = !state.isMobile
    },
    changeSize(state,payload){
      state.windowHeight = payload.windowHeight
      state.windowWidth = payload.windowWidth
    },
    switchIsFullscreen(state) {
      state.isFullscreen = !state.isFullscreen
    },
    faceApiIsReady(state) {
      state.faceapi_isReady = true
    }
  },
  actions: {
    loginByCredetials({ commit }, data) {
      const loginRequest = userApiService.login(data.username, data.password);
      localStorage.removeItem("iam.refreshmode.certificate")
      return _common_login({ commit }, loginRequest)
    },
    loginByCertificate({ commit }) {
      const loginRequest = userApiService.loginByCertificate();
      localStorage.setItem("iam.refreshmode.certificate", "true")
      return _common_login({ commit }, loginRequest)
    },
    loginByOneTimeToken({ commit }, data) {
      const token = data.token
      if (token === undefined || token == "") {
        //fail - no token provided
        commit('loginFailure');
        return Promise.reject("no token provided")
      }
      const loginRequest = userApiService.loginByOneTimeToken(token);
      // we want to use the normal token refresh logic from now on, even if we were authenticated via certificate
      localStorage.removeItem("iam.refreshmode.certificate")
      return _common_login({ commit }, loginRequest)
    },

    logout({ commit }, data) {
      let everywhere = "everywhere" in data ? data["everywhere"] : false;
      userApiService.logout(everywhere).finally(() => {
        localStorage.removeItem("iam.access-token")
        localStorage.removeItem("iam.refresh-token")
        localStorage.removeItem("iam.refreshmode.certificate")
        localStorage.removeItem("iam.identity")
        commit('logout');
      });
    },
    refreshTokenIfNeeded({ commit }) {
      let access_token = localStorage.getItem("iam.access-token")
      let access_token_decoded = decodeJWT(access_token, 10 * 60)
      if (access_token_decoded) {
        // token is still valid for at least 10 minutes
        return
      }
      const refresh_token = localStorage.getItem("iam.refresh-token")
      const refresh_token_decoded = decodeJWT(refresh_token)
      let action = null
      if (refresh_token_decoded) {
        action = userApiService.refreshToken();
      }
      else if (localStorage.getItem("iam.refreshmode.certificate") == "true") {
        loggingService.info("refresh with certificate")
        action = userApiService.loginByCertificate()
      } 
      else {
        loggingService.info("no valid refresh token found - cannot do anything")
        localStorage.removeItem("iam.access-token")
        localStorage.removeItem("iam.refresh-token")
        localStorage.removeItem("iam.identity")
        commit('logout');
        return
      }

      if (action == null) {
        loggingService.error("refresh action is null - this should not happen");
        throw new Error("refresh action is null - this should not happen");
      }

      action
        .then(_tokens => {
          const _decoded = handleTokenResponse(_tokens)
          commit('loginSuccess', _decoded);
          return Promise.resolve()
        })
        .catch(error => {
          loggingService.error("refresh failed - clear tokens", error)
          localStorage.removeItem("iam.access-token")
          localStorage.removeItem("iam.identity")
          localStorage.removeItem("iam.refresh-token")
          localStorage.removeItem("iam.refreshmode.certificate")
          commit('loginFailure');
          return Promise.resolve()
        });
    },
    initTokensAndRefresher() {
      let promise = null;
      if (localStorage && localStorage.getItem("iam.access-token")) {
          promise = userApiService.tokenValid()
              .then(ok => {
                  if (!ok) {
                      loggingService.info("Access token is invalid. Clearing it.")
                      localStorage.removeItem("iam.access-token");
                  }
                  return Promise.resolve();
              })
              .catch(err => {
                  loggingService.error("Error checking access token validity: " + err)
                  return Promise.resolve();
              });
      }
      else {
          promise = Promise.resolve();
      }

      promise.then(() => {
          this.dispatch('refreshTokenIfNeeded');
          window.setInterval(() => {
              this.dispatch('refreshTokenIfNeeded');
          }, 5 * 60 * 1000);
      });
    },

    resize({ commit }) {
      if (this.state.isMobile != (window.innerWidth <= window.innerHeight)){
        commit('switchIsMobile');
      }
      if (this.state.windowWidth != window.innerWidth || this.state.windowHeight != window.innerHeight){
        commit('changeSize',{windowWidth:window.innerWidth,windowHeight:window.innerHeight})
      }
    },
    fullscreenChange({ commit }) {
      if (this.state.isFullscreen != (document.fullscreenElement != null))
        commit('switchIsFullscreen');
    }
  },
  modules: {
  }
})
