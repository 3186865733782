import BaseApiService from "./BaseApiService"
import { ITokenInformationResponse, ICreateCloneTokenResponse } from "@/models/UserApi";

export default class UserApiService extends BaseApiService {
    constructor() {
        super('user');
    }

    login(username: string, password: string) {
        return this.makePost("/login", { username, password })
            .then(response => response.data as ITokenInformationResponse);
    }

    loginByCertificate() {
        return this.makePost("/login/certificate", {}, {}, 20000)
            .then(response => response.data as ITokenInformationResponse);
    }

    loginByOneTimeToken(one_time_token: string) {
        const headers = { "Authorization": `Bearer ${one_time_token}` };
        return this.makePost("/login/clonetoken", {}, headers)
            .then(response => response.data as ITokenInformationResponse);
    }

    createCloneToken() {
        return this.makeGet("/session/clonetoken")
            .then(response => response.data as ICreateCloneTokenResponse);
    }

    tokenValid() {
        return this.makeGet('/token/valid')
            .then(response => {
                return Promise.resolve(response.data === "valid");
            })
            .catch(() => Promise.resolve(false));
    }

    async refreshToken() {
        const response = await this.makePost("/token/refresh", {});
        return response.data as ITokenInformationResponse;
    }

    logout(everywhere = false) {
        const urlAdd = everywhere ? "/everywhere" : ""
        return this.makePost("/logout" + urlAdd, {})
            .then(response => {
                if (response.status !== 204) {
                    throw new Error(`HTTP error: ${response.status}`);
                }
            });
    }
}

