import BaseApiService from "./BaseApiService"
import store from "@/store"
import { loggingService } from "./ServiceFactory";

export default class TournamentApiService extends BaseApiService {
    constructor() {
        super('tournament');
    }
    loadTournament(tid) {
        return this.makeGet(`/${tid}`)
            .then(response => response.data)
            .catch(reason => {
                if (reason.status === 404) {
                    loggingService.warn("fallback to earlier behavior. LoadTournament returned 404. But no REJECT.")
                    return Promise.resolve(null);
                }
                return Promise.reject(reason)
            });
    }

    loadTournamentLastTwoDays() {
        if(store.state.scopes.includes('api.tournament.w'))
            return this.makeGet("/last_two_days")
                .then(response => response.data);
        else
            return Promise.resolve();
    }

    saveTournament(tid, content) {
        return this.makePostSuccessResponse("", { id: tid, content });
    }

    getEditUUID(tid) {
        if (store.state.scopes.includes('api.tournament.w')) {
            return this.makeGet(`/${tid}/edituuid`)
                .then(response => response.data);
        }
        else {
            return Promise.resolve();
        }
    }
}
